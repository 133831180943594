html,
body {
  margin: 0;
  height: 100%;
  font-family: 'Source Sans 3', Roboto, sans-serif;
  color: var(--zv-font);
}

*,
:after,
:before {
  box-sizing: border-box;
}

a {
  color: var(--zv-font);
}
