.display-block {
  display: block;
}

.height-100 {
  height: 100%;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.text-large {
  font-size: large;
}

.no-wrap {
  white-space: nowrap;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
