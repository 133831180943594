@use '@angular/material' as mat;
@use 'sass:map';
@use '@zvoove/components' as zv;

@import './color-variables';

// ==========================================
// = Material Theme Generierung
// ==========================================

// Define the typography map
// Name             CSS classes                         Description
// display-4	    .mat-display-4	                    Large, one-off header, usually at the top of the page (e.g. a hero header).
// display-3	    .mat-display-3	                    Large, one-off header, usually at the top of the page (e.g. a hero header).
// display-2	    .mat-display-2	                    Large, one-off header, usually at the top of the page (e.g. a hero header).
// display-1	    .mat-display-1	                    Large, one-off header, usually at the top of the page (e.g. a hero header).
// headline	        .mat-h1, .mat-headline	            Section heading corresponding to the <h1> tag.
// title	        .mat-h2, .mat-title	                Section heading corresponding to the <h2> tag.
// subheading-2	    .mat-h3, .mat-subheading-2	        Section heading corresponding to the <h3> tag.
// subheading-1	    .mat-h4, .mat-subheading-1	        Section heading corresponding to the <h4> tag.
// body-1	        .mat-body, .mat-body-1	            Base body text.
// body-2	        .mat-body-strong, .mat-body-2	    Bolder body text.
// caption	        .mat-small, .mat-caption	        Smaller body and hint text.
// button	        None. Used only in components.	    Buttons and anchors.
$general-typography: mat.m2-define-typography-config(
  $font-family: '"Source Sans 3", Roboto, sans-serif',
  $headline-1: mat.m2-define-typography-level(24px, 32px, 700),
  $headline-2: mat.m2-define-typography-level(20px, 28px, 700),
  $headline-3: mat.m2-define-typography-level(16px, 26px, 700),
  $headline-4: mat.m2-define-typography-level(16px, 26px, 700),
  $headline-5: mat.m2-define-typography-level(24px, 32px, 700),
  $headline-6: mat.m2-define-typography-level(20px, 28px, 700),
  $subtitle-1: mat.m2-define-typography-level(14px, 22px, 700),
  $body-1: mat.m2-define-typography-level(16px, 20px, 400),
  $subtitle-2: mat.m2-define-typography-level(14px, 20px, 500),
  $body-2: mat.m2-define-typography-level(14px, 18px, 400),
  $caption: mat.m2-define-typography-level(14px, 16px, 500),
  $button: mat.m2-define-typography-level(14px, 16px, 400),
) !default;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($general-typography);`
@include mat.all-component-typographies($general-typography);
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$base-app-primary: mat.m2-define-palette($color-primary);
$base-app-accent: mat.m2-define-palette($color-accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$base-app-warn: mat.m2-define-palette($color-error);

$base-foreground: (
  base: #1a1246,
  divider: rgba(black, 0.12),
  dividers: rgba(black, 0.12),
  disabled: #8d97ad,
  disabled-button: #8d97ad,
  disabled-text: #8d97ad,
  hint-text: #8d97ad,
  secondary-text: #8d97ad,
  icon: #1a1246,
  icons: #1a1246,
  text: #1a1246,
  slider-min: rgba(#1a1246, 0.87),
  slider-off: rgba(#1a1246, 0.26),
  slider-off-active: rgba(#1a1246, 0.38),
);

// Create the theme object (a Sass map containing all of the palettes).
$base-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $base-app-primary,
      accent: $base-app-accent,
      warn: $base-app-warn,
    ),
    typography: $general-typography,
    density: 0,
  )
);

$app-theme: map.set($base-app-theme, color, foreground, $base-foreground);

html {
  // Include theme styles for core and each component used in your app.
  // Alternatively, you can import and @include the theme mixins for each component
  // that you are using.
  @include mat.all-component-themes($app-theme);
  @include zv.zvoove-components-m2-theme($app-theme);
  @include global-variables($app-theme);
}
