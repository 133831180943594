@use '@angular/material' as mat;
@use 'sass:map';

//==========================================
//= Color Variable Definitions
//==========================================
@mixin global-variables($theme) {
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  // Font
  $foreground-palette: map.get($color-config, foreground);
  --zv-font-white: rgb(255, 255, 255);
  --zv-font-light: rgb(95, 89, 125);
  --zv-font: #{mat.m2-get-color-from-palette($foreground-palette, base)};

  // Material Colors
  $primary-palette: map.get($color-config, primary);
  --zv-primary-light: #{mat.m2-get-color-from-palette($primary-palette, 100)};
  --zv-primary: #{mat.m2-get-color-from-palette($primary-palette, 500)};
  --zv-primary-dark: #{mat.m2-get-color-from-palette($primary-palette, 900)};

  $accent-palette: map.get($color-config, accent);
  --zv-accent-light: #{mat.m2-get-color-from-palette($accent-palette, 100)};
  --zv-accent: #{mat.m2-get-color-from-palette($accent-palette, 500)};
  --zv-accent-dark: #{mat.m2-get-color-from-palette($accent-palette, 900)};

  $warn-palette: map.get($color-config, warn);
  --zv-error-light: #{mat.m2-get-color-from-palette($warn-palette, 100)};
  --zv-error: #{mat.m2-get-color-from-palette($warn-palette, 500)};
  --zv-error-dark: #{mat.m2-get-color-from-palette($warn-palette, 900)};

  // Hightlight Colors
  --zv-highlight-light: rgb(250, 215, 162);
  --zv-highlight: rgb(243, 155, 23);
  --zv-highlight-dark: rgb(146, 93, 14);

  // Success Colors
  --zv-success-light: rgb(169, 244, 169);
  --zv-success: rgb(41, 227, 41);
  --zv-success-dark: rgb(25, 136, 25);

  // Other
  --zv-app-background-light: rgb(249, 249, 249);
  --zv-app-background: rgb(244, 243, 246);
  --zv-app-background-dark: rgb(214, 217, 221);

  --zv-inactive: rgb(141, 151, 173);

  --zv-tag-turquoise: rgb(206, 233, 240);
  --zv-tag-green: rgb(220, 233, 183);
  --zv-tag-blue: rgb(178, 206, 255);
  --zv-tag-orange: rgb(250, 215, 162);
  --zv-tag-grey: rgb(209, 208, 218);
  --zv-tag-red: rgb(243, 202, 217);
}
